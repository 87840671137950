import React from "react"
import Navbar from "./Navbar";
import Footer from "./Footer";
import AboutUsLogo from "../assets/images/about.jpg"
import  "../assets/css/About.css"

const About = () => {

    return (
        <div>
            <Navbar/>

            <section id="about" className="about section-bg">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h3>Who We Are ?</h3>
                        <p className="color-white">Krishwin Infotech is a software development and designing company.We develop & design websites and have special expertise in providing sought after and original
                            services like database development, internet solutions, marketing as well as hosting..</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-6" data-aos="fade-right" data-aos-delay="100">
                            <img src={AboutUsLogo} className="img-fluid" alt=""/>
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center"
                            data-aos="fade-up" data-aos-delay="100">
                            <h4 className="side-content h4 color-white">Our History</h4>
                            <p className="color-white">Our company was incorporated in the year 2023 and we're positioned in the  regions of Karur,Tamil Nadu.
                                We're an ISO 9001:2015 certified organization and have Experience and Successful comitements in past years in the industry.</p>

                        </div>
                    </div>

                </div>
            </section>

            <Footer/>
        </div>
    )
}

export  default About;