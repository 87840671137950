import React from "react"
import Logo from "../assets/images/kriswin.png"

const Navbar = () => {

    let pathname = window.location.pathname

    return (  
        <div className="sticky-top"> 
        <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
                <a href="/" className="navbar-brand p-0">
                    <h1 className="m-0"><img src={Logo} style={{ width: "250px" }} /></h1>
            </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target={"#navbarCollapse"}>
                    <span className="fa fa-bars"></span>
            </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto py-0">
                        <a className={"nav-link scrollto " + (pathname === "/" ? "active" : "")} href="/">Home</a>
                        <a className={"nav-link scrollto " + (pathname === "/about" ? "active" : "")} href="/about">About</a>
                        <a className={"nav-link scrollto " + (pathname === "/team" ? "active" : "")} href="/team">Team</a>
                        <a className={"nav-link scrollto " + (pathname === "/services" ? "active" : "")} href="/services">Services</a>
                        <a className={"nav-link scrollto " + (pathname === "/contact" ? "active" : "")} href="/contact">Contact</a>
                   
                </div>
                
            </div>
        </nav>
        </div>
    )
}

export default Navbar;