import React from 'react'
import Navbar from "./Navbar";
import Footer from "./Footer";

function DevOps() {
	return (

		<div>
			<Navbar />
			<div>
				<h2>DevOps</h2>
			</div>
			<Footer />
		</div>
	)
}
export default DevOps;