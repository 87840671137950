import React from "react"
import Logo from "../assets/images/kriswin.png";

const Footer = () => {

    return(
        <footer id="footer">

            <div className="footer-top">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-3 col-md-6 footer-contact">
                            <img src={Logo} style={{width: "250px"}}/>
                            <p></p>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"></i> <a href="/">Home</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="/about">About us</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="/services">Services</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="#">Terms of service</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="#">Privacy policy</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"></i> <a href="#">Web Design</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="#">Web Development</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Our Social Networks</h4>
                            <p>Have Any Questions? Mail Us To</p>
                            <p>software@kriswin-infotech.com</p>
                            <div className="social-links mt-3">
                                <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
                                <a href="https://www.facebook.com/profile.php?id=61551948817271" target="_blank" className="facebook"><i className="bx bxl-facebook"></i></a>
                                <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
                                <a href="https://www.linkedin.com/company/97413348/admin/feed/posts/"  target="_blank" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <hr></hr>
            <div className="container footer-align-copyrights">
                <div className="copyright">
                    &copy; Copyright <strong><span>Kriswin infotech</span></strong>. All Rights Reserved
                </div>
            </div>
        </footer>
    )

}

export default Footer;