import React from "react"
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from 'react-router-dom';

const Service = () => {

    return (
        <div>
            <Navbar/>

            <main id="main">

                <section id="services" className="services">
                    <div className="container" data-aos="fade-up">

                        <div className="section-title">
                            <h2>Services</h2>
                            <h3>Check our <span>Services</span></h3>
                            <p>Krishwin Infotech creating successful environment to the developers as well as clients.we provide a attractive and creative Web Applicaation
                             and Services to our Clients.</p>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in"
                                 data-aos-delay="100">
                                <div className="icon-box">
                                    <div className="icon"><i className="bx bxl-microsoft"></i></div>
                                    <h4><Link to="/software-development">Software Development & Services</Link></h4>
                                    <p>We creating,designing,deploying and supporting Software and also provide development,management & services.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
                                 data-aos="zoom-in" data-aos-delay="200">
                                <div className="icon-box">
                                    <div className="icon"><i className="bx bx-mobile"></i></div>
                                    <h4><Link to="/mobile-app">Mobile Application Development</Link></h4>
                                    <p>Mobile app development involves designing, developing, testing, and deploying mobile apps for iOS (Apple App Store) and Android (Google Play Store),
                                        as well as other operating systems such as Windows .</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
                                 data-aos="zoom-in" data-aos-delay="300">
                                <div className="icon-box">
                                    <div className="icon"><i className="bx bx-desktop"></i></div>
                                    <h4><Link to="/web-app">Web Development & Services</Link></h4>
                                    <p>Web development & services to the creating, building, and maintaining of websites. It includes aspects such as web design,
                                        web publishing, web programming, and database management.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in"
                                 data-aos-delay="100">
                                <div className="icon-box">
                                    <div className="icon"><i className="bx bxl-dev-to"></i></div>
                                    <h4><Link to="/devops">DevOps</Link></h4>
                                    <p> integrate the work of development teams and operations teams by facilitating a culture of collaboration and shared responsibility</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in"
                                 data-aos-delay="200">
                                <div className="icon-box">
                                    <div className="icon"><i className="bx bx-conversation"></i></div>
                                    <h4><Link to="/it-consulting">IT Consulting</Link></h4>
                                    <p>helping clients on how they can utilise information technology (IT) and digital to optimally achieve their business goals.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in"
                                 data-aos-delay="300">
                                <div className="icon-box">
                                    <div className="icon"><i className="bx bx-broadcast"></i></div>
                                    <h4><Link to="/networksecurity">Network Security</Link></h4>
                                    <p>Protect Your Network Infrastructure against Advanced Threats & Malware.</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>

            </main>

            <Footer />
        </div>
    )
}

export default Service;