import "./assets/css/bootstrap.min.css"
import "./assets/css/main.css"
import "./assets/css/boxicons.min.css"
import { BrowserRouter , Routes, Route } from "react-router-dom";
import Home from "./components/Home"
import About from "./components/About"
import Contact from "./components/Contact"
import Team from "./components/Team"
import Service from "./components/Service"
import SoftwareDevelopment from "./components/SoftwareDevelopment";
import MobileApp from "./components/MobileApp";
import WebDevelopment from "./components/WebApp";
import DevOps from "./components/DevOps";
import ITconsulting from "./components/ITconsulting";
import NetworkSecurity from "./components/NetworkSecurity";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route>
              <Route path="/"  element={<Home />} />
              <Route path="/about"  element={<About />} />
              <Route path="/team"  element={<Team />} />
              <Route path="/services" element={<Service />} />
                      <Route path="/software-development" element={< SoftwareDevelopment />} />
                      <Route path="/mobile-app" element={< MobileApp />} />
                      <Route path="/web-app" element={< WebDevelopment />} />
                      <Route path="/devops" element={< DevOps />} />
                      <Route path="/it-consulting" element={< ITconsulting />} />
                      <Route path="/networksecurity" element={< NetworkSecurity />} />
              <Route path="/contact" element={<Contact />} /> 
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
