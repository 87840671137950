import React from "react"
import Navbar from "./Navbar";
import Footer from "./Footer";

const Home = () => {

    return (
        <div>
            <Navbar/>

            <section id="hero" className="d-flex align-items-center">
                <div className="container" data-aos="zoom-out" data-aos-delay="100">
                    <h1>Welcome to <span>Kriswin</span></h1>
                    <h2>We are team of talented web and mobile application developers.</h2>
                </div>
            </section>

            <main id="main">

                <section id="featured-services" className="featured-services">
                    <div className="container" data-aos="fade-up">

                        <div className="row 1 mb-5">
                            <div className="container mb-2">
                                <div className="icon-box our-mission-vision" data-aos="fade-up" data-aos-delay="100">
                                    <h4>Mission</h4>
                                    <p>Our mission is to become preferred & trusted supplier by providing valued services,
                                        solutions and products to our customers that can achieve dynamic and sustainable results.
                                        We are on a mission to become relentless reformers and leading Information Technology Company in the global market.</p>
                                </div>
                            </div>
                            <div className="container ">
                                <div className="icon-box our-mission-vision" >
                                    <h4>Vision</h4>
                                    <p>Our vision is to continue improving our brand value by transforming & simplifying
                                        complex businesses through innovative Information Technology services and solutions.</p>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                                <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                                    <div className="icon"><i className="bx bx-message-rounded-edit"></i></div>
                                    <h4 className="title"><a href="">Creative Ideas</a></h4>
                                    <p className="description">coming up with a unique idea that introduces a new way of thinking to the market.</p>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                                <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
                                    <div className="icon"><i className="bx bx-briefcase-alt-2"></i></div>
                                    <h4 className="title"><a href="">Advanced Tools</a></h4>
                                    <p className="description">we provide most advanced tools to create, maintain, test, debug, build and support other applications and software.
                                        There are various types of tools like languages, monitoring platforms, databases, frameworks, etc</p>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                                <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
                                    <div className="icon"><i className="bx bx-tachometer"></i></div>
                                    <h4 className="title"><a href="">Perfomance Optimized</a></h4>
                                    <p className="description">we enhancing the efficiency and responsiveness of an application, system, or service.</p>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                                <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
                                    <div className="icon"><i className="bx bx-medal"></i></div>
                                    <h4 className="title"><a href="">Goal Achievement</a></h4>
                                    <p className="description">Mobilize Your Team,Enhance Collaboration,Facilitate Innovation,Complete Your Cloud Migration and Integration.
                                        Optimize Cloud Costs,Clean Up Your Communications Technology Stack,Add a Contact Center Solution.</p>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-8 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0">
                                <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                                    <h4 className="title"><a href="">Current Projects</a></h4>
                                    <p>60</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0">
                                <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                                    <h4 className="title"><a href="">Completed projects</a></h4>
                                    <p>10</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </main>

            <Footer />
        </div>
    )
}

export default Home;