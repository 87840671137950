import React from 'react'
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../assets/css/SoftwareDevelopment.css"
import SoftwareImage from "../assets/images/Software.jpg"

function SoftwareDevelopment() {


    return (

        <div>
            <Navbar/>
            <main>

                <section>
                    <div className="software-title">
                        <h1>Services</h1>
                    </div>
                    <div data-aos="fade-up">
                        <section id="Software" className="image-container">
                            <img src={SoftwareImage} alt=""></img>
                            <div className="overlay-text" data-aos="zoom-out" data-aos-delay="100">
                                <h1>Software Development & Services</h1>
                                <p>Software development services help plan, design, develop, integrate, test, manage and
                                    evolve software solutions.
                                    Quality-centered and adherent to mature software development practices,
                                    Krishwin Infotech provides application development services to startups, software
                                    companies, and enterprises across any industries..</p>
                            </div>
                        </section>
                        <div className="container">
                            <div>
                                <p>
                                    We specialize in creating custom software solutions tailored to your specific needs.
                                    Our team of experienced developers will work closely with you to deliver
                                    high-quality, efficient, and scalable software.
                                </p>
                            </div>
                            <section>
                                <h2>Quality Assurance</h2>
                                <p>
                                    Our dedicated QA team ensures the reliability and functionality of your software,
                                    conducting rigorous testing to identify and rectify any issues before deployment.
                                </p>
                            </section>
                            <section>
                                <h2>Why Krishwin Infotech ?</h2>
                                <div className="soft-row ms-5">
                                    <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                                        <div>
                                            <h2><span>1</span> Years</h2>
                                            <p>Experience in Software Industry</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                                        <div>
                                            <h2><span>10</span> + Projects</h2>
                                            <p>Successfully Completed</p></div>
                                    </div>
                                </div>

                            </section>
                        </div>
                    </div>
                </section>

            </main>
            <Footer/>
        </div>
    )
}

export default SoftwareDevelopment;