import React from 'react'
import Navbar from "./Navbar";
import Footer from "./Footer";

function MobileApp() {
	return (

		<div>
			<Navbar />
			<div>
				<h2>Mobile Application Development</h2>
			</div>
			<Footer />
		</div>
	)
}
export default MobileApp;