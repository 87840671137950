import React, { useState } from "react"
import Navbar from "./Navbar";
import Footer from "./Footer";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify'


const InitialState = {
    name: '',
    email: '',
    subject: '',
    message: ''
}

toast.configure();
const Contact = () => {

    const [contactObject, setContactObject] = useState(InitialState);

    const handleFormSubmit = (event) => {
        event.preventDefault();

        axios.post("https://workroom.kriswin-infotech.com/contacts", { contact: contactObject }, {headers: {
                'Content-Type': 'application/json',
                Accept: "*/*",

            }}).then(res => {
            setContactObject(InitialState)
            toast.success("Thanks for contacting us. We will get back to you shortly", { position: toast.POSITION.TOP_RIGHT })
        }).catch(error => {
        })
    }

    return (
        <div>
            <Navbar/>

            <main id="main">


                <section id="contact" className="contact">
                    <div className="container" data-aos="fade-up">

                        <div className="section-title">
                            <h2>Contact</h2>
                            <h3><span>Contact Us</span></h3>
                            <p>Talk to an Expert</p>
                        </div>

                        <div className="row" data-aos="fade-up" data-aos-delay="100">
                            <div className="col-lg-6">
                                <div className="info-box mb-4">
                                    <i className="bx bx-map"></i>
                                    <h3>Our Address</h3>
                                    <p>No.95, BC Street, Malapatti, Modakkur east, Aravakurichi, Karur, Tamil Nadu - 639 207</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="info-box  mb-4">
                                    <i className="bx bx-envelope"></i>
                                    <h3>Email Us</h3>
                                    <p>software@kriswin-infotech.com</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="info-box  mb-4">
                                    <i className="bx bx-phone-call"></i>
                                    <h3>Call Us</h3>
                                    <p>+917010950016</p>
                                </div>
                            </div>

                        </div>

                        <div className="row" data-aos="fade-up" data-aos-delay="100">

                            <div className="col-lg-6">
                                <form onSubmit={handleFormSubmit}  className="php-email-form">
                                    <div className="row">
                                        <div className="col form-group">
                                            <input type="text"  className="form-control"
                                                   value={contactObject.name}
                                                   onChange={event => setContactObject({
                                                       ...contactObject, name: event.target.value
                                                   })}
                                                   placeholder="Your Name" required/>
                                        </div>
                                        <div className="col form-group">
                                            <input type="email" className="form-control"
                                                   value={contactObject.email}
                                                   onChange={event => setContactObject({
                                                       ...contactObject, email: event.target.value
                                                   })}
                                                   placeholder="Your Email" required/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" value={contactObject.subject}
                                               onChange={event => setContactObject({
                                                   ...contactObject, subject: event.target.value
                                               })}
                                               placeholder="Subject" required/>
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control"
                                                  value={contactObject.message}
                                                  onChange={event => setContactObject({
                                                      ...contactObject, message: event.target.value
                                                  })}
                                                  name="message" rows="5" placeholder="Message"
                                                  required></textarea>
                                    </div>

                                    <div className="text-center">
                                        <button className="btn btn-primary">Send Message</button>
                                    </div>
                                </form>
                            </div>

                        </div>

                    </div>
                </section>

            </main>

            <Footer />
        </div>
    )

}

export  default Contact;