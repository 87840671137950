import React from "react"
import Navbar from "./Navbar";
import Footer from "./Footer";
import KishoreImage from "../assets/images/kishore.jpeg"
import SantharamImage from "../assets/images/santharam.jpeg"

const Team = () => {

    return (
        <div>
            <Navbar/>

            <main id="main">


                <section id="team" className="team section-bg">
                    <div className="container" data-aos="fade-up">

                        <div className="section-title">
                            <h2>Team</h2>
                            <h3>Our Hardworking <span>Team</span></h3>
                            <p>Empowering Innovation: Meet Our Dynamic Software Team.</p>
                        </div>
                        <div className="row">

                            <div className="col-lg-6 col-md-6 d-flex align-items-stretch"
                                 data-aos-delay="100">
                                <div className="member">
                                    <div className="member-img">
                                        <img src={SantharamImage} className="img-fluid" style={{height: "750px"}} alt=""/>
                                    </div>
                                    <div className="member-info">
                                        <h4>Santharam Gopalakrishnan</h4>
                                        <span>Chief Executive Officer</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 d-flex align-items-stretch" >
                                <div className="member">
                                    <div className="member-img">
                                        <img src={KishoreImage} className="img-fluid" style={{height: "750px"}} alt=""/>
                                    </div>
                                    <div className="member-info">
                                        <h4>Kishore Gopalakrishnan</h4>
                                        <span>Chief Technology officer</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>


            </main>

            <Footer />
        </div>
    )
}

export default Team;